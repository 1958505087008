@import './../../Variables.scss';

.burger-menu {
  display: none;
  .bm-burger-button {
    width: 30px;
    position: fixed;
    top: 16px;
    left: 25px;
    button {
      width: 30px !important;
      height: 30px !important;
      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    display: block;
  }
}

.bm-item-list {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px;
  }
}
