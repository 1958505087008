@import "./Variables.scss";

//GENERAL

body {
  margin: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-family: $OpenSans;
  background-color: map-get($theme-colors, "background");
}

.corpo {
  position: relative;
  height: 100vh;
  width: calc(100% - 130px);
  padding: 0 32px 32px;
  float: left;
  overflow-y: scroll;
  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 0 16px;
  }
}

//CENTRATURA ELEMENTI

.u-center-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-center-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.u-center-horizontal {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.u-center-horizontal-inner {
  display: table;
  margin: 0 auto;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
// BACKGROUND

.u-bg-light {
  background-color: map-get($theme-colors, "white");
}

// CONT SHADOW - CARD

.card {
  &.login-card {
    margin-top: -80px;
    max-width: 600px;
    @media only screen and (max-width: 575px) {
      margin-top: -40px;
    }
    .card-header {
      background-color: map-get($theme-colors, "white");
      border: none;
      text-align: center;
      padding-top: 30px;
      .card-title {
        font-size: 20px;
        color: map-get($theme-colors, "darkborder");
        font-weight: 500;
        span {
          color: map-get($theme-colors, "black");
          font-weight: 800;
        }
      }
    }
    .card-body {
      padding-right: 40px !important;
      padding-left: 40px !important;
      .btn {
        min-width: 200px;
      }
    }
  }
}

.card {
  background-color: map-get($theme-colors, "white");
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  border: none;
  overflow: hidden;
  margin-bottom: 32px;
  .card-header {
    background-color: map-get($theme-colors, "primary");
    border: 0;
    .card-title {
      margin-bottom: 0;
      color: map-get($theme-colors, "white");
      font-weight: 700;
    }
  }
  .card-body {
    padding: 32px 16px;
  }
  .card-footer {
    background-color: map-get($theme-colors, "grey");
    border-top: 1px solid map-get($theme-colors, "darkborder");
    padding-top: 24px;
    padding-bottom: 24px;
    color: map-get($theme-colors, "darkborder");
    .row {
      .col-6 {
        &:first-child {
          font-weight: 700;
        }
        &:last-child {
          a {
            color: map-get($theme-colors, "primary");
          }
        }
      }
    }
  }
}

.cont-white-shadow {
  background-color: map-get($theme-colors, "white");
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  padding: 30px 15px;
  border-radius: 20px;
}

// FORM E LABEL

form {
  .form-group {
    margin-bottom: 24px;
    label {
      color: map-get($theme-colors, "darkborder");
      font-size: 12px;
    }
    .form-text {
      font-size: 10px;
      color: map-get($theme-colors, "grey");
    }
    .custom-control-label {
      font-style: italic;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: map-get($theme-colors, "primary-contrast");
      background-color: map-get($theme-colors, "primary-contrast");
    }
  }
  @media only screen and(max-width: 1564px) {
    &.filter {
      .form-group {
        width: 20%;
        padding-right: 8px;
        label {
          width: 100%;
          justify-content: left;
        }
        select {
          width: 100%;
          justify-content: left;
          margin-right: 0 !important;
          margin-left: 0 !important;
        }
        input {
          width: 100%;
          margin-right: 0px !important;
          margin-left: 0px !important;
        }
      }
    }
  }
  @media only screen and(max-width: 837px) {
    &.filter {
      .form-group {
        width: 33.3%;
        &:nth-child(4),
        &:last-child {
          margin-top: 10px;
        }
      }
    }
  }
  @media only screen and(max-width: 575px) {
    &.filter {
      .form-group {
        margin-bottom: 0;
        label {
          margin-bottom: 0;
        }
        // input{
        //   overflow: hidden;
        // }
      }
    }
  }
}

// BUTTON

.primary-button {
  background-color: map-get($theme-colors, "primary");
  padding: 8px;
  color: map-get($theme-colors, "white");
  border: 0;
  min-width: max-content;
  border-radius: 5px;
  svg {
    fill: white;
    width: 20px;
  }
}

.primary-button:disabled {
  background-color: map-get($theme-colors, "grey");
}

.secondary-button {
  background-color: map-get($theme-colors, "danger");
  padding: 8px;
  color: map-get($theme-colors, "white");
  border: 0;
  min-width: max-content;
  border-radius: 5px;
  svg {
    fill: white;
    width: 20px;
  }
}

.secondary-button:disabled {
  background-color: map-get($theme-colors, "grey");
}

.btn {
  background-color: map-get($theme-colors, "primary-contrast");
  color: map-get($theme-colors, "white");
  border: 0;
  border-radius: 8px;
  padding: 7px 25px !important;
  margin-top: 16px;
  font-weight: 600;
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  padding: 30px 15px;
  font-size: 20px;
  &:hover {
    background-color: map-get($theme-colors, "primary");
    color: map-get($theme-colors, "white");
  }
  img {
    width: 25px;
    margin-right: 7px;
  }
}

.btn-plus {
  background-color: map-get($theme-colors, "primary");
  font-size: 15px;
  margin-top: 0;
  img {
    width: 20px;
  }
}

.btn-collapse {
  margin-top: 0;
  margin-bottom: 0 !important;
  font-size: 16px;
  color: map-get($theme-colors, "primary");
  background-color: white;
  &:focus {
    -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
    outline: none;
  }
}

.position-btn-filter {
  position: absolute;
  top: 0;
  right: 16px;
  @media only screen and (max-width: 1196px) {
    position: relative;
    top: inherit;
    right: inherit;
    width: 100%;
  }
  @media only screen and (max-width: 575px) {
    margin-top: 0;
  }
}

.resp-btn {
  @media only screen and (max-width: 991px) {
    margin-top: 15px;
  }
  @media only screen and (max-width: 575px) {
    margin-top: 0;
  }
}
// TEXT

h4 {
  color: map-get($theme-colors, "primary");
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 16px;
}

.no-activities {
  color: map-get($theme-colors, "primary");
  text-align: center;
  font-weight: 600;
}

// MARGIN - PAGGING

.m-b-35 {
  margin-bottom: 35px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.m-t-20 {
  margin-top: 20px;
}

// CONT-GREY

.cont-grey {
  background-color: map-get($theme-colors, "background");
  margin-top: 20px;
  margin-bottom: 20px;
  .cont-grey-header {
    padding: 24px 16px;
    border-bottom: 1px solid map-get($theme-colors, "border");
    .col-6 {
      &:first-child {
        h6 {
          color: map-get($theme-colors, "primary");
          font-weight: 700;
          font-size: 18px;
          margin-bottom: 0;
        }
      }
      &:last-child {
        h6 {
          color: map-get($theme-colors, "primary-contrast");
          font-size: 18px;
          margin-bottom: 0;
        }
      }
    }
  }
  .cont-grey-body {
    padding: 16px;
    color: map-get($theme-colors, "black");
    p {
      margin-bottom: 0;
    }
  }
}

// REACT-DATE PICKER

.react-datepicker-wrapper {
  input {
    padding: 6px 12px;
    &:focus {
      box-shadow: none;
      outline: 0;
    }
  }
}

.react-datepicker {
  border: 1px solid map-get($theme-colors, "border");
  font-family: $OpenSans;
  .react-datepicker__triangle {
    border-bottom-color: map-get($theme-colors, "border");
  }
  .react-datepicker__header {
    border-bottom: 1px solid map-get($theme-colors, "border");
    .react-datepicker__current-month {
      color: map-get($theme-colors, "primary-contrast");
    }
    .react-datepicker__day-names {
      .react-datepicker__day-name {
        color: map-get($theme-colors, "black");
      }
    }
  }
}

.react-datepicker__day {
  color: map-get($theme-colors, "black") !important;
  &:hover {
    border-radius: 50%;
  }
}

.react-datepicker__day--today {
  border-radius: 50%;
}

.react-datepicker__day--selected {
  background-color: map-get($theme-colors, "primary-contrast") !important;
  color: map-get($theme-colors, "white") !important;
  border-radius: 50%;
}
