@import './../../Variables.scss';

.page-not-found {
  text-align: center;
  height: 100vh;
  .container {
    height: 100%;
  }
  img {
    width: 200px;
    margin-bottom: 40px;
  }
  h1 {
    font-weight: 700;
    font-size: 48px;
  }
  button {
    margin-top: 20px;
  }
}
