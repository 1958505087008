@import "../../Variables.scss";

.table {
  td,
  th {
    vertical-align: middle;
  }
}

.position-btn-filter {
  .spinner-container {
    margin-top: 10px;
  }
}

.approve-button {
  background-color: map-get($theme-colors, "success");
  padding: 8px;
  color: map-get($theme-colors, "white");
  border: 0;
  border-radius: 5px;
  margin-bottom: 10px;
  svg {
    fill: white;
    width: 20px;
  }
}

.approve-button:disabled {
  background-color: #dcf0d4;
}

.deny-button {
  background-color: map-get($theme-colors, "danger");
  padding: 8px;
  color: map-get($theme-colors, "white");
  border: 0;
  border-radius: 5px;
  svg {
    fill: white;
    width: 20px;
  }
}

.deny-button:disabled {
  background-color: #ffb1b1;
}

.file-upload-container {
  display: flex;
  .form-group {
    margin-bottom: 0;
  }
  p {
    background-color: map-get($theme-colors, "white") !important;
    border: 1px solid map-get($theme-colors, "primary") !important;
    padding: 8px;
    margin: 0;
    width: 170px;
    cursor: default;
  }
  .custom-label {
    background-color: map-get($theme-colors, "primary") !important;
    color: white;
    padding: 9px;
    cursor: pointer;
    width: 100px;
    margin-bottom: 0;
    display: flex;
    align-items: baseline;
  }
  .custom-label.disabled {
    background-color: map-get($theme-colors, "grey") !important;
    cursor: default;
  }
}
