@import "./../../App.scss";

.modal-dialog {
  max-width: 700px;
}
.modal-content {
  border: 0;
  background-color: map-get($theme-colors, "white");
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  .modal-header {
    background-color: map-get($theme-colors, "primary");
    color: map-get($theme-colors, "white");
    text-align: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    .modal-title {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 600;
    }
    .close {
      color: map-get($theme-colors, "white");
    }
  }
  .modal-body {
    p {
      font-size: 14px;
    }
    .approve-badge {
      display: flex;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: map-get($theme-colors, "success");
      svg {
        fill: white;
        width: 15px;
      }
    }
    .deny-badge {
      display: flex;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: map-get($theme-colors, "danger");
      svg {
        fill: white;
        width: 15px;
      }
    }
  }
  .modal-footer {
    border-top: 0;
    .btn-secondary {
      background-color: map-get($theme-colors, "danger");
    }
  }
}
