// VARIABLES

$theme-colors: (
  'background': #f8f6f6,
  'black': #222d32,
  'white': #ffffff,
  'grey': #dddddd,
  'pink': #e0c7c2,
  'primary': #3c8dbc,
  'primary-contrast': #ff6b38,
  'border': #c9c9c9,
  'darkborder': #777777,
  'success': #8fc485,
  'danger': #d87373,
  'warning': #ed9b00,
  'appointment_scheduled': #ffb238,
  'qualified_to_buy': #ff6b38,
  'presentation_scheduled': #c75146,
  'contract_sent': #ad2e24,
  'closed_won': #81171b,
  'closed_lost': #540804,
  'freezed': #87a6fa,
);

$OpenSans: 'Open Sans', sans-serif;

$menu-size: 130px;
