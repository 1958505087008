@import './../../App.scss';

footer {
  position: fixed;
  width: calc(100% - 130px);
  right: 0;
  bottom: 0;
  background-color: map-get($theme-colors, 'grey');
  padding: 8px 32px;
  font-size: 12px;
  z-index: 2;
  @media only screen and (max-width: 767px) {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
}
